import {
  NodeSamplesDisplay,
  NodeSamplesStatType,
} from "../../../pages/SiteStats/types";
import {
  ChartDataType,
  ChartElement,
  ChartObjects,
  StatDatasets,
} from "./types";
import { TimestreamNodePPM } from "../../../store/timestreamNodePPM/types";
import { adjustTo30sBasis } from "../../../helpers/dayjsHelpers";
import { Node } from "../../../store/nodes/types";
import type { DeviceData, DeviceDataFormat, NodeColors } from "../types";
import { nodeSamplesKeyToStatType } from "../../../pages/SiteStats/helpers";
import { ChartDataset } from "chart.js";

const LINE_BORDER_WIDTH = 1.3; // chartJS default is 2

export const makeChartTitle = (
  nodeSamplesDisplay: NodeSamplesDisplay
): string => {
  const gasTypes: string[] = [];
  if (nodeSamplesDisplay.doShowCH4) {
    gasTypes.push("CH4");
  }
  if (nodeSamplesDisplay.doShowVOC) {
    gasTypes.push("VOC");
  }
  if (nodeSamplesDisplay.doShowCO2) {
    gasTypes.push("CO2");
  }

  if (gasTypes.length === 0) {
    return "";
  }

  const chartTitle = gasTypes.join(" & ") + " (PPM)";
  return chartTitle;
};

export const makeChartElements = (
  selectedNodes: Node[],
  nodeColors: NodeColors
): ChartElement[] => {
  const chartElements = selectedNodes.map((node, idx) => {
    const { NodeId: nodeId, name } = node;
    const color = nodeColors[nodeId];
    const border = adjustHexBrightness(nodeColors[node.NodeId], 1.1);
    return {
      label: name,
      key: nodeId,
      color,
      border,
    };
  });
  return chartElements;
};

export const makeChartObjects = (
  data: TimestreamNodePPM[],
  chartElements: ChartElement[]
): ChartObjects => {
  const sourceData: TimestreamNodePPM[] = [];
  const labels: string[] = [];

  const chartDatasets: Record<string, DeviceData> = {};
  for (const chartEle of chartElements) {
    chartDatasets[chartEle.key] = { ch4: [], voc: [], co2: [] };
  }

  for (const ele of data) {
    const roundedTimestamp = adjustTo30sBasis(ele.Timestamp);
    sourceData.push({ ...ele, Timestamp: roundedTimestamp });
    labels.push(roundedTimestamp);

    for (const chartEle of chartElements) {
      let ch4Val: number | null = null;
      let vocVal: number | null = null;
      let co2Val: number | null = null;

      if (ele.NodeId === chartEle.key) {
        if (ele.gas1D != null) {
          ch4Val = Number(ele.gas1D) > 0 ? ele.gas1D : null;
        }
        if (ele.gas2D != null) {
          vocVal = Number(ele.gas2D) > 0 ? ele.gas2D : null;
        }
        if (ele.co2 != null) {
          co2Val = Number(ele.co2) > 0 ? ele.co2 : null;
        }
      }

      chartDatasets[chartEle.key].ch4.push(ch4Val);
      chartDatasets[chartEle.key].voc.push(vocVal);
      chartDatasets[chartEle.key].co2.push(co2Val);
    }
  }

  return { labels, sourceData, chartDatasets };
};

export const makeChartData = (
  nodeSamplesDisplay: NodeSamplesDisplay,
  chartElements: ChartElement[],
  chartObjects: ChartObjects
): ChartDataType => {
  const { labels, chartDatasets } = chartObjects;

  const statDatasets = makeStatDatasets(
    chartElements,
    chartDatasets,
    nodeSamplesDisplay
  );

  const chartData: ChartDataType = { labels, datasets: [] };
  const nodeSamplesDisplayEntries = Object.entries(nodeSamplesDisplay) as Array<
  [keyof NodeSamplesDisplay, boolean]
  >;
  for (const [key, doDisplay] of nodeSamplesDisplayEntries) {
    if (doDisplay) {
      const statType = nodeSamplesKeyToStatType(key);
      let statDataset: Array<ChartDataset<"line", DeviceDataFormat>> | null;

      switch (statType) {
        case NodeSamplesStatType.CH4: {
          statDataset = statDatasets.CH4;
          break;
        }
        case NodeSamplesStatType.VOC: {
          statDataset = statDatasets.VOC;
          break;
        }
        case NodeSamplesStatType.CO2: {
          statDataset = statDatasets.CO2;
          break;
        }
      }

      if (statDataset != null) {
        Object.assign(chartData, {
          datasets: [...chartData.datasets, ...statDataset],
        });
      }
    }
  }

  return chartData;
};

const makeStatDatasets = (
  chartElements: ChartElement[],
  chartDatasets: Record<string, DeviceData>,
  nodeSamplesDisplay: NodeSamplesDisplay
): StatDatasets => {
  const result: StatDatasets = {
    [NodeSamplesStatType.CH4]: [],
    [NodeSamplesStatType.VOC]: [],
    [NodeSamplesStatType.CO2]: [],
  };

  chartElements.forEach((element) => {
    let lineDashLength = 0;

    const commonObj = {
      backgroundColor: element.color,
      borderColor: element.border,
      borderWidth: LINE_BORDER_WIDTH,
      spanGaps: true,
      radius: 0,
      hoverRadius: 0,
    };

    if (nodeSamplesDisplay.doShowCH4) {
      result.CH4.push({
        label: `${element.label} CH4 (PPM)`,
        yAxisID: NodeSamplesStatType.CH4,
        data: chartDatasets[element.key].ch4,
        borderDash: [lineDashLength, lineDashLength],
        ...commonObj,
      });
      lineDashLength += 2;
    }

    if (nodeSamplesDisplay.doShowVOC) {
      result.VOC.push({
        label: `${element.label} VOC (PPM)`,
        yAxisID: NodeSamplesStatType.VOC,
        data: chartDatasets[element.key].voc,
        borderDash: [lineDashLength, lineDashLength],
        ...commonObj,
      });
      lineDashLength += 2;
    }

    if (nodeSamplesDisplay.doShowCO2) {
      result.CO2.push({
        label: `${element.label} CO2 (PPM)`,
        yAxisID: NodeSamplesStatType.CO2,
        data: chartDatasets[element.key].co2,
        borderDash: [lineDashLength, lineDashLength],
        ...commonObj,
      });
      lineDashLength += 2;
    }
  });

  return result;
};

const adjustHexBrightness = (
  hexColor: string,
  brightnessChange: number
  // 1 = no change
  // 1.1 = 10% brighter
  // 0.9 = 10% darker
): string => {
  // Convert hex to RGB
  const bigint = parseInt(hexColor.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Adjust brightness
  const adjustedR = Math.min(255, Math.round(r * brightnessChange));
  const adjustedG = Math.min(255, Math.round(g * brightnessChange));
  const adjustedB = Math.min(255, Math.round(b * brightnessChange));

  // Convert back to hex
  const newHexColor = `#${(
    (1 << 24) |
    (adjustedR << 16) |
    (adjustedG << 8) |
    adjustedB
  )
    .toString(16)
    .slice(1)}`;

  return newHexColor;
};
