import { Node } from "../../store/nodes/types";
import { NodePPM } from "../../store/nodesPPM/types";
import { TimestreamNodePPM } from "../../store/timestreamNodePPM/types";
import type { NodeSamplesDisplay } from "../../pages/SiteStats/types";

export interface NodePPMProps {
  nodePPM: TimestreamNodePPM[];
  nodes: Node[];
  selectedNodeIds: string[];
  siteNodeOptions: Array<{ id: string; name: string }>;
  timezone: string;
  resetChartKey: number;
  chartTimerange: { startTimestamp: string; endTimestamp: string };
  handleSetChartTimerange: (
    startTimestamp: string,
    endTimestamp: string
  ) => void;
  nodeSamplesDisplay: NodeSamplesDisplay;
}

export interface NodePPMChartProps {
  timezone: string;
  data: TimestreamNodePPM[];
  selectedNodes: Node[];
  nodeColors: NodeColors;
  resetChartKey: number;
  chartTimerange: { startTimestamp: string; endTimestamp: string };
  handleSetChartTimerange: (
    startTimestamp: string,
    endTimestamp: string
  ) => void;
  nodeSamplesDisplay: NodeSamplesDisplay;
}

export interface NodePPMTableProps {
  timezone: string;
  siteNodeOptions: Array<{ id: string; name: string }>;
  data: NodePPM[];
}

export interface NodeColors {
  [key: string]: string;
}

export interface TooltipContext {
  dataset: { label?: string | undefined };
  dataIndex: number;
}

export type DeviceDataFormat = Array<number | null>;

export interface DeviceData {
  ch4: DeviceDataFormat;
  voc: DeviceDataFormat;
  co2: DeviceDataFormat;
}

export const emptyTimestreamNodePPMItem = {
  NodeId: "",
  Timestamp: "",
  gas1D: -1,
  gas2D: -1,
};
