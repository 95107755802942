import { FC } from "react";
import styles from "../../styles.module.scss";
import { QrCodePagesProps } from "../../types";

const QrCodePages: FC<QrCodePagesProps> = ({ qrCodes, selectedRowKeys }): JSX.Element => {
  const qrCodePrintout = (qrCodes: string[]): JSX.Element[] => {
    return qrCodes.map((dataURL, index) => (
      <div
        key={index}
        className={styles.QrCodeWrapper}
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={dataURL}
          height="180px"
          style={{
            display: "block",
          }}
          alt={`QR code for ${selectedRowKeys[index]}`}
        />
        <span
          style={{
            position: "absolute",
            top: "170px",
          }}
        >
          {selectedRowKeys[index]}
        </span>
      </div>
    ));
  };

  return (
    <div className={styles.QrCodePagesContainer}>
      {qrCodePrintout(qrCodes)}
    </div>
  );
};

export default QrCodePages;
