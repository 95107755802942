import { Button, Table } from "antd";
import { FC } from "react";
import {
  useDeleteBusinessUnitMutation,
  useGetBusinessUnitsQuery,
} from "../../store/businessUnits/api";
import { currentOrganizationSelector } from "../../store/organizations/selector";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCurrentBusinessUnit } from "../../store/businessUnits/slice";
import { useNavigate } from "react-router-dom";
import { BusinessUnit } from "../../store/businessUnits/types";
import { BusinessUnitsTableData } from "./types";

const BusinessUnits: FC = () => {
  const currentOrganization = useAppSelector(currentOrganizationSelector);
  const orgId = currentOrganization !== null ? currentOrganization.OrgId : "";
  const { data, isLoading } = useGetBusinessUnitsQuery(orgId);
  const [deleteBusinessUnitTrigger] = useDeleteBusinessUnitMutation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  let tableData: BusinessUnitsTableData[] = [];

  if (data !== null && data !== undefined) {
    tableData = data.result.map((item: BusinessUnit) => {
      return {
        tagName: item.tagName,
        key: item.tagId,
      };
    });
  }

  const onSelectBusinessUnit = (tagId: number): void => {
    if (data !== undefined) {
      const businessUnit = data.result.find((el) => el.tagId === tagId);
      if (businessUnit !== undefined) {
        dispatch(setCurrentBusinessUnit(businessUnit));
        navigate(`/business-units/${tagId}`);
      }
    }
  };

  const onEditBusinessUnit = (tagId: number): void => {
    if (data !== undefined) {
      const businessUnit = data.result.find((el) => el.tagId === tagId);
      if (businessUnit !== undefined) {
        dispatch(setCurrentBusinessUnit(businessUnit));
        navigate(`/edit-business-unit/${tagId}`);
      }
    }
  };

  const onDeleteBusinessUnit = (tagId: number): void => {
    deleteBusinessUnitTrigger({ tagId })
      .unwrap()
      .then((res) => {
        if (res.Message === "Ok") {
          window.alert("Business Unit Successfully Deleted");
        }
      })
      .catch((e) => console.log(e));
  };

  const columns = [
    {
      title: "Business Unit Name",
      dataIndex: "tagName",
      key: "tagName",
      render: (text: string, item: BusinessUnitsTableData) => (
        <Button type="link" onClick={() => onSelectBusinessUnit(item.key)}>
          {text}
        </Button>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, item: BusinessUnitsTableData) => (
        <>
          <Button
            type="link"
            onClick={() => onEditBusinessUnit(item.key)}
            style={{ marginRight: 8 }}
          >
            Edit
          </Button>
          <Button
            type="link"
            danger
            onClick={() => onDeleteBusinessUnit(item.key)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Button type="primary" onClick={() => navigate("/new-business-unit")}>
          New Business Unit
        </Button>
      </div>
      <Table dataSource={tableData} columns={columns} loading={isLoading} />
    </div>
  );
};

export default BusinessUnits;
