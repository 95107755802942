import { FC, useEffect, useState } from "react";
import { Button, Modal, Radio, Tag, Tooltip } from "antd";
import {
  SearchOutlined,
  LeftOutlined,
  RightOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  CarOutlined,
} from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { sideBarItemsSelector } from "../../store/ui/selector";
import { SideBarElement } from "../../store/ui/types";
import { setSideBarElements } from "../../store/ui/slice";
import {
  addPushpins,
  zoomInSite,
  createSitePushPin,
  zoomInSites,
  handleBingMapsSearch,
  removePolylines,
  removePushPins,
} from "../../pages/OrganizationMap/mapApi";
import { currentOrganizationSelector } from "../../store/organizations/selector";
import { setCurrentOrganization } from "../../store/organizations/slice";
import { Site } from "../../store/sites/types";
import { setCurrentSite } from "../../store/sites/slice";
import { showSideNavigationSelector } from "../../store/orgMap/selector";
import {
  setShowSideNavigation,
  setShowMapController,
} from "../../store/orgMap/slice";
import { organizationsApi } from "../../store/organizations/api";
import { customerAlarmIncidentApi } from "../../store/alarmIncidents/api";
import { sitesApi } from "../../store/sites/api";
import { SideNavigationProps } from "./types";
import styles from "./styles.module.scss";
import {
  AlarmIncidentParams,
  AlarmIncidents,
} from "../../store/alarmIncidents/types";
import StatusTag from "../StatusTags/StatusTags";
import {
  initSinglePushPinClickCallback,
  removeMapHandler,
} from "../../pages/SiteMapv2/mapApi";
import { CreateSite } from "../CreateSiteComponent/CreateSite";
import { Coordinates } from "../MapController/types";
import VehicleFleet from "../VehicleFleet/VehicleFleet";
import { gpsTrackitApi } from "../../store/gpsTrackit/api";
import { GPSVehicle } from "../../store/gpsTrackit/types";

const SideNavigation: FC<SideNavigationProps> = ({
  isAdmin,
  showPPMLayer,
  point1,
  setCurrentSelectedPointCB,
}) => {
  const currentOrg = useAppSelector(currentOrganizationSelector);
  const showSideNavigation: boolean = useAppSelector(
    showSideNavigationSelector
  );
  const dispatch = useAppDispatch();
  const initialSelection = isAdmin
    ? currentOrg !== null
      ? "sites"
      : "orgs"
    : "sites";
  const [selection, setSelection] = useState<"orgs" | "sites" | "vehicles">(
    initialSelection
  );

  const [getSitesTrigger] = sitesApi.useLazyGetSitesQuery();

  const [vehicles, setVehicles] = useState<GPSVehicle[]>();
  const [isVehiclesLoading, setIsVehiclesLoading] = useState<boolean>(false);
  const [selectedVehicle, setSelectedVehicle] = useState<GPSVehicle>();
  const [getVehiclesTrigger] = gpsTrackitApi.useLazyGetVehiclesQuery();
  const [showVehicleModal, setShowVehicleModal] = useState<boolean>(false);

  const [getCustomerAlarmIncidentsTrigger] =
    customerAlarmIncidentApi.useLazyGetCustomerAlarmIncidentsQuery();
  const [searchInput, setSearchInput] = useState("");

  const [getOrganizationsTrigger, resultGetOrganizations] =
    organizationsApi.endpoints.getOrganizations.useLazyQuery();
  const [isAddSiteOn, setIsAddSiteOn] = useState(false);
  const resetSideNavigation = (): void => {
    removePolylines();
    removePushPins();
    setSearchInput("");
    dispatch(setSideBarElements([]));
    setIsAddSiteOn(false);
    removeMapHandler();
  };

  const [sortAscending, setSortAscending] = useState(true);
  const [statusFilters, setStatusFilters] = useState({
    searching: true,
    alarm: true,
    ok: true,
  });

  const handleSetShowSideNavigation = (): void => {
    dispatch(setShowSideNavigation(!showSideNavigation));
  };

  const handleSetSideBarElements = (elements: SideBarElement[]): void => {
    const sorted = elements.sort((a, b) => {
      const aIsActive = a.isActive !== null && a.isActive === true;
      const bIsActive = b.isActive !== null && b.isActive === true;
      if (aIsActive && !bIsActive) return -1;
      if (!aIsActive && bIsActive) return 1;

      return a.title.localeCompare(b.title);
    });

    dispatch(setSideBarElements(sorted));
  };

  const onClickOrgs = (): void => {
    resetSideNavigation();
    setSelection("orgs");
    if (resultGetOrganizations?.data !== undefined) {
      const sideBarElements = resultGetOrganizations?.data.map((item) => {
        return {
          title: item.name,
          subtitle: item.country,
          onClick: "selectOrganization",
          id: item.OrgId,
          organization: item,
          description: "",
        };
      });
      handleSetSideBarElements(sideBarElements);
    }
  };

  const onClickVehicles = async (): Promise<void> => {
    setIsVehiclesLoading(true);
    try {
      const getVehiclesResponse = await getVehiclesTrigger(null);
      const vehicles = getVehiclesResponse.data?.body;
      if (vehicles !== null && vehicles !== undefined) {
        setVehicles(vehicles);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsVehiclesLoading(false);
    }
  };
  const onClickVehiclesModal = async (): Promise<void> => {
    await onClickVehicles();
    setSelectedVehicle(undefined);
    setShowVehicleModal(true);
  };
  const onClickCancelVehicleModal = (): void => {
    setShowVehicleModal(false);
  };

  const sitePushPinCallback = (site: Site) => {
    return () => {
      dispatch(setShowSideNavigation(false));
      dispatch(setShowMapController(true));
      dispatch(setCurrentSite(site));
      zoomInSite({
        latitude: site?.details?.latAPI,
        longitude: site?.details?.longAPI,
      });
    };
  };

  const onSiteSelection = (result: AlarmIncidents[]): void => {
    const sideBarElements = result.flatMap((org) => {
      const elements = org.sites.map((site) => {
        return {
          title: site.name,
          subtitle: "",
          onClick: "selectSite",
          id: site.SiteId,
          details: site.details,
          isActive: site.active,
          description: "",
          site,
        };
      });
      return elements;
    });
    if (result.length > 0) {
      const sitesPushPins = result.flatMap((org) => {
        const pushPins = org.sites.map((site) => {
          return createSitePushPin(site, sitePushPinCallback(site));
        });
        return pushPins;
      });
      addPushpins(sitesPushPins);
      const coordinates = sitesPushPins.map((x) => {
        return {
          latitude: Number(x.center?.latitude),
          longitude: Number(x.center?.longitude),
        };
      });
      zoomInSites(coordinates);
    }
    handleSetSideBarElements(sideBarElements);
    setSelection("sites");
  };

  const onClickSites = (): void => {
    resetSideNavigation();
    if (currentOrg == null) {
      setSelection("orgs");
      window.alert("Please select an organization");
    } else {
      const orgId = currentOrg.OrgId;
      const params: AlarmIncidentParams = {
        ids: orgId,
      };
      getCustomerAlarmIncidentsTrigger(params)
        .unwrap()
        .then((result) => {
          onSiteSelection(result);
        })
        .catch((e) => console.log("error: ", e));
    }
  };

  const onClickItem = async (item: SideBarElement): Promise<void> => {
    if (item.onClick === "selectOrganization") {
      if (item.organization !== undefined) {
        dispatch(setCurrentOrganization(item.organization));

        const orgId = item.organization.OrgId;
        const getSitesResponse = await getSitesTrigger(orgId);
        const sites = getSitesResponse.data ?? [];
        const coordinates = sites.map((site: Site) => {
          return {
            latitude: site?.details?.latAPI,
            longitude: site?.details?.longAPI,
          };
        });
        zoomInSites(coordinates);
      }
      resetSideNavigation();
      dispatch(setCurrentSite(null));
      const params: AlarmIncidentParams = {
        ids: item.id,
      };
      getCustomerAlarmIncidentsTrigger(params)
        .unwrap()
        .then((result) => {
          onSiteSelection(result);
        })
        .catch((e) => console.log(e));
    } else {
      if (item.site?.details !== undefined) {
        setSearchInput("");
        handleSetShowSideNavigation();
        setIsAddSiteOn(false);
        removeMapHandler();
        dispatch(setShowMapController(true));
        dispatch(setCurrentSite(item.site));
        zoomInSite({
          latitude: item.site.details.latAPI,
          longitude: item.site.details.longAPI,
        });
        if (currentOrg === null && item.organization !== undefined) {
          dispatch(setCurrentOrganization(item.organization));
        }
      }
    }
  };

  const filterSideBarElements = (
    elements: SideBarElement[],
    searchString: string
  ): SideBarElement[] => {
    return elements.filter((element) => {
      const { title, subtitle } = element;

      const isTextMatch =
        title.toLowerCase().includes(searchString.toLowerCase()) ||
        subtitle.toLowerCase().includes(searchString.toLowerCase());

      const { alarm, ok, searching } = statusFilters;

      if (
        selection === "sites" &&
        element.site?.status !== null &&
        element.site?.status !== undefined
      ) {
        if (element.site.status === "High PPM" && !searching) {
          return false;
        }
        if (element.site.status === "Alert" && !searching) {
          return false;
        }
        if (element.site.status === "Alarm" && !alarm) {
          return false;
        }
        if (element.site.status === "Ok" && !ok) {
          return false;
        }
        return true && isTextMatch;
      }
      return isTextMatch;
    });
  };

  const sideBarElements = useAppSelector(sideBarItemsSelector);

  const filteredSideBarElements = filterSideBarElements(
    sideBarElements,
    searchInput
  );

  const sortedElements = sortAscending
    ? filteredSideBarElements
    : filteredSideBarElements.sort((a, b) => {
      const aIsActive = a.isActive !== null && a.isActive === true;
      const bIsActive = b.isActive !== null && b.isActive === true;
      if (aIsActive && !bIsActive) return -1;
      if (!aIsActive && bIsActive) return 1;
      return b.title.localeCompare(a.title);
    });

  const handleClickCreateSiteCallback = (
    selectedPoint: number,
    location: Coordinates
  ): void => {
    setCurrentSelectedPointCB(selectedPoint, location);
  };

  const handleAddSiteInitiation = (): void => {
    setIsAddSiteOn(!isAddSiteOn);
    initSinglePushPinClickCallback(handleClickCreateSiteCallback);
  };

  useEffect(() => {
    setSelection(isAdmin ? (currentOrg !== null ? "sites" : "orgs") : "sites");
  }, [isAdmin]);

  useEffect(() => {
    getOrganizationsTrigger(null)
      .unwrap()
      .then()
      .catch((e) => console.log("error: ", e));
  }, []);

  const MAX_TITLE_LENGTH = 12;

  return (
    <div
      className={styles.SideNavigation}
      style={{
        height: showPPMLayer ? "calc(100% - 210px)" : "100%",
      }}
    >
      <Button
        icon={showSideNavigation ? <RightOutlined /> : <LeftOutlined />}
        style={{ margin: 10, border: "none" }}
        onClick={handleSetShowSideNavigation}
      />
      <Modal
        centered
        open={showVehicleModal}
        title={<span>{"Vehicles"}</span>}
        onCancel={onClickCancelVehicleModal}
        onOk={onClickCancelVehicleModal}
        width="400px"
        footer={null}
      >
        <VehicleFleet
          vehicles={vehicles}
          isLoading={isVehiclesLoading}
          selectedVehicle={selectedVehicle}
          setSelectedVehicle={setSelectedVehicle}
        />
      </Modal>
      {showSideNavigation ? (
        <div className={styles.SideDrawerWrapper}>
          {isAdmin ? (
            <div className={styles.RadioGroupWrapper}>
              <Radio.Group
                buttonStyle="solid"
                optionType="button"
                value={selection}
              >
                <Radio.Button value="orgs" onClick={onClickOrgs}>
                  Orgs
                </Radio.Button>
                <Radio.Button value="sites" onClick={onClickSites}>
                  Sites
                </Radio.Button>
              </Radio.Group>
            </div>
          ) : null}
          <div className={styles.SearchBarWrapper}>
            <div>
              <div className={styles.SearchBar}>
                <input
                  className={styles.SearchInput}
                  id="searchBox"
                  type="text"
                  placeholder="Bing Maps Search"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleBingMapsSearch();
                    }
                  }}
                />
                <SearchOutlined
                  className={styles.SearchIcon}
                  onClick={() => handleBingMapsSearch()}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 15,
              marginLeft: 10,
            }}
          >
            <div>
              <Button
                icon={
                  sortAscending ? (
                    <SortAscendingOutlined />
                  ) : (
                    <SortDescendingOutlined />
                  )
                }
                onClick={() => setSortAscending(!sortAscending)}
              />
            </div>
            {selection === "sites" && (
              <div
                style={{
                  justifySelf: "center",
                  marginLeft: 10,
                  cursor: "pointer",
                }}
              >
                {isAdmin && (
                  <Button
                    icon={<CarOutlined />}
                    onClick={onClickVehiclesModal}
                    style={{
                      marginRight: "5px",
                    }}
                  ></Button>
                )}
                <Tag
                  color={statusFilters.alarm ? "red" : "gray"}
                  onClick={() =>
                    setStatusFilters({
                      ...statusFilters,
                      alarm: !statusFilters.alarm,
                    })
                  }
                >
                  Alarm
                </Tag>
                <Tag
                  color={statusFilters.searching ? "blue" : "gray"}
                  onClick={() =>
                    setStatusFilters({
                      ...statusFilters,
                      searching: !statusFilters.searching,
                    })
                  }
                >
                  Searching
                </Tag>
                <Tag
                  color={statusFilters.ok ? "success" : "gray"}
                  onClick={() =>
                    setStatusFilters({
                      ...statusFilters,
                      ok: !statusFilters.ok,
                    })
                  }
                >
                  OK
                </Tag>
              </div>
            )}
          </div>

          <div
            style={{
              overflow: "auto",
              height:
                selection === "sites" && isAdmin
                  ? "calc(100% - 280px)"
                  : "calc(100% - 160px)",
              paddingBottom: 10,
            }}
          >
            {sortedElements.map((element, idx) => {
              return (
                <div
                  onClick={async () => await onClickItem(element)}
                  key={idx}
                  className={styles.SideBarCard}
                >
                  {selection === "orgs" ? (
                    <div className={styles.Flex}>
                      <div className={styles.CardTextWrapper}>
                        <div className={styles.CardTitle}>{element.title}</div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className={styles.ActiveStatusContainer}>
                        {element.isActive !== undefined ? (
                          element.isActive ? (
                            <div className={styles.ActiveText}>Active</div>
                          ) : (
                            <div className={styles.InactiveText}>Inactive</div>
                          )
                        ) : null}
                      </div>
                      <div className={styles.Flex}>
                        <div className={styles.CardTextWrapper}>
                          <div className={styles.CardTitle}>
                            <Tooltip title={element.title}>
                              {element.title.length > MAX_TITLE_LENGTH
                                ? `${element.title.slice(
                                    0,
                                    MAX_TITLE_LENGTH
                                  )}...`
                                : element.title}
                            </Tooltip>
                          </div>
                        </div>
                        <div className={styles.StatusTagContainer}>
                          <StatusTag
                            siteStatus={
                              element.site?.status !== null &&
                              element.site?.status !== undefined
                                ? element.site.status
                                : ""
                            }
                            isActive={
                              element.site?.active !== null &&
                              element.site?.active !== undefined
                                ? element.site.active
                                : false
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          {selection === "sites" && isAdmin && (
            <CreateSite
              isAddSiteOn={isAddSiteOn}
              setIsAddSiteOn={setIsAddSiteOn}
              handleAddSiteInitiation={handleAddSiteInitiation}
              onClickSites={onClickSites}
              point={point1}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default SideNavigation;
