import { FC, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

// DAYJS
import { Dayjs } from "dayjs";
import { getDateString } from "../../helpers/dayjsHelpers";
import {
  setStartTimestamp,
  setEndTimestamp,
} from "../../store/selections/slice";
import { getNewEndTimestamp } from "../TimeRangePicker/helpers";
import { startTimestampSelector } from "../../store/selections/selector";

// GPS TRACKIG
import { gpsTrackitApi } from "../../store/gpsTrackit/api";
import {
  GPSVehicleRouteParams,
  GPSVehicle,
} from "../../store/gpsTrackit/types";

// MAP API
import {
  addPolyLines,
  removePolylines,
  zoomInSites,
  addPushpins,
  removeThesePushPins,
} from "../../pages/OrganizationMap/mapApi";

// COMPONENTS
import { Spin } from "antd";
import { ReactComponent as TruckIcon } from "./truck.svg";
import { ANTD_BLUE } from "../LayoutV2/LayoutV2";
import DatePickerWithArrows from "../DatePickerWithArrows/DatePickerWithArrows";
import styles from "./styles.module.scss";

interface VehicleFleetProps {
  vehicles: GPSVehicle[] | null | undefined;
  isLoading: boolean;
  selectedVehicle: GPSVehicle | undefined;
  setSelectedVehicle: (vehicle: GPSVehicle) => void;
}

const VehicleFleet: FC<VehicleFleetProps> = ({
  vehicles,
  isLoading,
  selectedVehicle,
  setSelectedVehicle,
}) => {
  const dispatch = useAppDispatch();
  const startTimestamp = useAppSelector(startTimestampSelector);
  const [getVehicleRoutesTrigger] = gpsTrackitApi.useLazyGetVehicleRouteQuery();
  const [vehiclePushPins, setVehiclePushPins] = useState<
  Microsoft.Maps.Pushpin[]
  >([]);
  const [isWaiting, setIsWatiting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(
    (vehicles != null) && vehicles.length > 0 ? "" : "No Earthview Vehicles to retrieve"
  );

  useEffect(() => {
    if (isWaiting) {
      setTimeout(() => {
        setIsWatiting(false);
      }, 3000); // GPS Trackit API rate limiting
    }
  }, [isWaiting]);
  const handleVehicleRoutes = async (
    vehicle: GPSVehicle,
    startDate: string
  ): Promise<void> => {
    setIsWatiting(true);
    setErrorMessage("");
    try {
      const params: GPSVehicleRouteParams = {
        id: String(vehicle.id),
        date: startDate,
      };
      const getVehicleRoutesResponse = await getVehicleRoutesTrigger(params);

      if (getVehicleRoutesResponse.error != null) {
        setErrorMessage(
          "Error retrieving the data. Please note the vehicle API is restricted to one request every 10s."
        );
        return;
      }
      const vehicleRoutes = getVehicleRoutesResponse.data?.body;

      if (vehicleRoutes != null && vehicleRoutes.length > 0) {
        removePolylines();
        addPolyLines(vehicleRoutes);

        const lastLocation = vehicleRoutes[vehicleRoutes.length - 1];
        const pushPin = [
          {
            center: {
              latitude: lastLocation.latitude,
              longitude: lastLocation.longitude,
            },
            options: {
              title: vehicle.label,
            },
          },
        ];
        if (
          vehiclePushPins !== null &&
          vehiclePushPins !== undefined &&
          vehiclePushPins.length > 0
        ) {
          removeThesePushPins(vehiclePushPins);
        }
        const outputPushPins = addPushpins(pushPin, false, true);
        if (outputPushPins != null && outputPushPins !== undefined) {
          setVehiclePushPins(outputPushPins);
        }
        zoomInSites(vehicleRoutes);
      } else {
        setErrorMessage("No vehicle routes on given date");
      }
    } catch (error) {
      setErrorMessage(
        "Error retrieving the data. Please note the vehicle API is restricted to one request every 10s."
      );
      console.log("Error handling vehicle routes:", error);
    }
  };

  const onDateChange = async (
    date: Dayjs,
    dateString: string | string[]
  ): Promise<void> => {
    const newDateString = Array.isArray(dateString)
      ? dateString[0]
      : dateString;
    const newStartTimestamp = newDateString + " 00:00:00";
    const newEndTimestamp = getNewEndTimestamp(newStartTimestamp, 1, 0);
    dispatch(setStartTimestamp(newStartTimestamp));
    dispatch(setEndTimestamp(newEndTimestamp));
    if (selectedVehicle != null) {
      const dayString = getDateString(newStartTimestamp);
      await handleVehicleRoutes(selectedVehicle, dayString);
    }
  };

  const onSelectVehicle = async (vehicle: GPSVehicle): Promise<void> => {
    setSelectedVehicle(vehicle);
    const dayString = getDateString(startTimestamp);
    await handleVehicleRoutes(vehicle, dayString);
  };

  return (
    <div className={styles.Center} style={{ flexDirection: "column" }}>
      {isLoading || isWaiting ? (
        <Spin className={styles.Center} />
      ) : (
        <>
          <div style={{ margin: "10px" }}>
            <DatePickerWithArrows
              selectedDate={startTimestamp}
              onDateChange={onDateChange}
            />
          </div>
          {vehicles !== undefined && vehicles !== null && (
            <div>
              {vehicles.map((vehicle: GPSVehicle) => {
                const borderColor =
                  selectedVehicle?.id === vehicle.id ? ANTD_BLUE : "silver";
                return (
                  <div
                    key={vehicle.id}
                    className={styles.VehicleWrapper}
                    onClick={async () => await onSelectVehicle(vehicle)}
                    style={{
                      borderColor,
                    }}
                  >
                    <span className={styles.Center}>
                      <TruckIcon style={{ padding: "5px" }} />
                      {vehicle.label}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
          {errorMessage !== "" && <div>{errorMessage}</div>}
        </>
      )}
    </div>
  );
};

export default VehicleFleet;
