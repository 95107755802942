import { FC, useState, useEffect } from "react";
import { useAppSelector } from "../../store/hooks";
import { currentBusinessUnitSelector } from "../../store/businessUnits/selector";
import { useGetSitesQuery } from "../../store/sites/api";
import { currentOrganizationSelector } from "../../store/organizations/selector";
import {
  useGetBusinessUnitSitesQuery,
  useUpdateBusinessUnitSitesMutation,
} from "../../store/businessUnits/api";

import { Button, Table } from "antd";
import { BusinessUnitTableData } from "./types";
import { useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";

const BusinessUnit: FC = () => {
  const currentBusinessUnit = useAppSelector(currentBusinessUnitSelector);
  const currentOrganization = useAppSelector(currentOrganizationSelector);
  const orgId = currentOrganization !== null ? currentOrganization.OrgId : "";
  const businessUnitId =
    currentBusinessUnit !== null ? String(currentBusinessUnit.tagId) : "";
  const sitesResponse = useGetSitesQuery(orgId);
  const businessUnitSitesResponse =
    useGetBusinessUnitSitesQuery(businessUnitId);
  const [updateBusinessUnitSites] = useUpdateBusinessUnitSitesMutation();
  const navigate = useNavigate();

  const sitesData = sitesResponse.data;
  const businessUnitSitesData = businessUnitSitesResponse.data;

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const columns: ColumnsType<BusinessUnitTableData> = [
    {
      title: "Site Name",
      dataIndex: "name",
    },
  ];

  const tableData: BusinessUnitTableData[] =
    sitesData !== undefined
      ? sitesData
        .filter((site) => site.active)
        .map((site) => ({
          key: site.SiteId,
          name: site.name,
          siteId: site.SiteId,
        }))
      : [];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys: React.Key[]) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const handleUpdate = (): void => {
    if (currentBusinessUnit?.tagId !== undefined) {
      const siteIds = selectedRowKeys.map((el) => String(el));
      const payload = {
        siteIds,
        tagId: currentBusinessUnit?.tagId,
      };
      updateBusinessUnitSites(payload)
        .unwrap()
        .then((res) => {
          if (res.result.Message === "SUCCESS") {
            window.alert("Successfully update sites");
          }
        })
        .catch((e) => console.log(e));
    }
  };

  useEffect(() => {
    if (businessUnitSitesData != null && sitesData != null) {
      const preselectedKeys = sitesData
        .filter((site) =>
          businessUnitSitesData.result.some(
            (bSite) => bSite.site.apiId === site.SiteId
          )
        )
        .map((site) => site.SiteId);
      setSelectedRowKeys(preselectedKeys);
    }
  }, [businessUnitSitesData, sitesData]);

  return (
    <div>
      <Button
        type="link"
        onClick={() =>
          navigate(`/organization/${orgId}/organization-management`)
        }
        style={{ marginLeft: 0, paddingLeft: 0, marginBottom: 5 }}
      >
        Go Back
      </Button>
      <h3>{currentBusinessUnit?.tagName}</h3>
      <div>
        <Table<BusinessUnitTableData>
          columns={columns}
          dataSource={tableData}
          rowSelection={rowSelection}
        />
      </div>
      <Button type="primary" onClick={handleUpdate}>
        Update
      </Button>
    </div>
  );
};

export default BusinessUnit;
