import { QuantityUnit } from "../../helpers/unitsHelpers";

export interface NodeOption {
  id: string;
  name: string;
  active: boolean;
}

export interface UnitOption {
  value: string;
  label: string;
}

export const unitOptions: UnitOption[] = [
  {
    value: QuantityUnit.MCF,
    label: QuantityUnit.MCF,
  },
  {
    value: QuantityUnit.KG,
    label: QuantityUnit.KG,
  },
];

export const STATS_TYPES = {
  AvgEmissionRates: "6",
  NodeSamples: "7"
};

export const epaCheckboxOptions: UnitOption[] = [
  { label: "90-day", value: "90-day" },
  { label: "7-day", value: "7-day" },
  { label: "Baseline", value: "Baseline" },
];

export enum NodeSamplesStatType {
  CH4 = "CH4",
  VOC = "VOC",
  CO2 = "CO2",
}

export const nodeSamplesCheckboxOptions: UnitOption[] = [
  { label: NodeSamplesStatType.CH4, value: NodeSamplesStatType.CH4 },
  { label: NodeSamplesStatType.VOC, value: NodeSamplesStatType.VOC },
  { label: NodeSamplesStatType.CO2, value: NodeSamplesStatType.CO2 },
];

export interface EpaBaselineDisplays {
  doShow90Day: boolean;
  doShow7Day: boolean;
  doShowSiteBaseline: boolean;
}

export interface NodeSamplesDisplay {
  doShowCH4: boolean;
  doShowVOC: boolean;
  doShowCO2: boolean;
}

export interface SiteStatsSelectionProps {
  selectedStat: string;
  statTypeOptions: Array<{ label: string; value: string }>;
  handleStatChange: (stat: string) => void;
  onNodeChange: (newNodes: string[]) => void;
  siteNodeOptions: NodeOption[];
  selectedNodeIds: string[];
  unitOptions: UnitOption[];
  selectedUnitOption: QuantityUnit;
  onUnitOptionChange: (unit: QuantityUnit) => void;
  refreshData: () => void;
  disabled: boolean;
  showLocalTime: boolean;
  handleChangeShowLocalTime: (newValue: boolean) => void;
  handleResetChartKey: () => void;
  handleSetChartTimerange: (
    startTimestamp: string,
    endTimestamp: string
  ) => void;
  doEnableEpasiteBaseline: boolean;
  setEpaBaselineDisplay: (newValue: EpaBaselineDisplays) => void;
  nodeSamplesDisplay: NodeSamplesDisplay;
  setNodeSamplesDisplay: (newValue: NodeSamplesDisplay) => void;
}

export type TimeSeriesUnit = "hour" | "day";
