import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BusinessUnit } from "./types";

interface BusinessUnitsState {
  currentBusinessUnit: BusinessUnit | null;
}

const initialState: BusinessUnitsState = {
  currentBusinessUnit: null,
};

export const businessUnitsSlice = createSlice({
  name: "businessUnits",
  initialState,
  reducers: {
    setCurrentBusinessUnit: (
      state: BusinessUnitsState,
      action: PayloadAction<BusinessUnit>
    ) => {
      state.currentBusinessUnit = action.payload;
    },
  },
});

export const { setCurrentBusinessUnit } = businessUnitsSlice.actions;

export default businessUnitsSlice.reducer;
