import { SitePushPin } from "../../components/SideNavigation/images/icons";
import { NodePushPin } from "../../assets/icons";
import { polarToSvgRotation } from "../SiteMapv2/windHelper";
import { removeOverlay, topographicOverlay } from "./imageOverlayHelper";
import { siteStatuses } from "../../components/StatusTags/types";
import { ANTD_BLUE } from "../../components/LayoutV2/LayoutV2";

const bingMapsKey = process.env.REACT_APP_BING_MAP_KEY;

export const setMapViewOptions = (map, Maps, viewOptions) => {
  const options = { ...viewOptions };
  if (viewOptions.mapTypeId) {
    options.mapTypeId = Maps.MapTypeId[viewOptions.mapTypeId];
  }
  if (viewOptions.hideRoadLabels) {
    options.labelOverlay = Maps.LabelOverlay.hidden;
  }
  map.setView(options);
};

export const makeMap = (mapContainerRef, viewOptions) => {
  const Maps = window.Microsoft.Maps;
  const map = new Maps.Map(mapContainerRef.current, {
    credentials: bingMapsKey,
    showDashboard: false
  });

  window.map = map;

  if (viewOptions) {
    setMapViewOptions(map, Maps, viewOptions);
  }
};

let searchManager;
export const geocodeQuery = (query) => {
  const map = window.map;
  const searchRequest = {
    where: query,
    callback: (r) => {
      if (r && r.results && r.results.length > 0) {
        const firstResult = r.results[0];
        const center = firstResult.bestView.center;
        map.setView({ center, zoom: 18 });
      }
    },
    errorCallback: (e) => {
      alert("No results found.");
    }
  };
  searchManager.geocode(searchRequest);
};

export const handleBingMapsSearch = () => {
  const map = window.map;
  const Maps = window.Microsoft.Maps;
  if (!searchManager) {
    Maps.loadModule("Microsoft.Maps.Search", () => {
      searchManager = new Maps.Search.SearchManager(map);
      performSearch(geocodeQuery);
    });
  } else {
    map.entities.clear();
    const query = document.getElementById("searchBox").value;
    geocodeQuery(query);
  }
};

const performSearch = (geocodeQuery) => {
  const map = window.map;
  if (searchManager) {
    map.entities.clear();
    const query = document.getElementById("searchBox").value;
    geocodeQuery(query);
  }
};

export const removePushPins = () => {
  const map = window.map;
  const Maps = window.Microsoft.Maps;
  for (let i = map.entities.getLength() - 1; i >= 0; i--) {
    const pushpin = map.entities.get(i);
    if (pushpin instanceof Maps.Pushpin) {
      map.entities.removeAt(i);
    }
  }
};

export const removeThesePushPins = (pushPinRegistry) => {
  const map = window.map;
  if (!map) return;
  pushPinRegistry.forEach((pin) => map.entities.remove(pin));
};

export const addPushpins = (
  pushPins,
  doRemovePushPins = true,
  doReturnPushPins = false
) => {
  if (doRemovePushPins) {
    removePushPins();
  }
  const map = window.map;
  const Maps = window.Microsoft.Maps;
  const allPushPins = [];

  pushPins.forEach((pushPin) => {
    if (pushPin === null) {
      return;
    }
    const newPin = new Maps.Pushpin(pushPin.center, pushPin.options);
    if (Object.prototype.hasOwnProperty.call(pushPin, "onClick")) {
      Maps.Events.addHandler(newPin, "click", () => pushPin.onClick());
    }
    map.entities.push(newPin);
    if (doReturnPushPins) {
      allPushPins.push(newPin);
    }
  });
  if (doReturnPushPins) {
    return allPushPins;
  }
};

export const addPolyLines = (coordinates) => {
  const map = window.map;
  const Maps = window.Microsoft.Maps;
  const locations = coordinates.map(
    (point) => new Maps.Location(point.latitude, point.longitude)
  );
  const polyline = new Maps.Polyline(locations, {
    strokeColor: ANTD_BLUE,
    strokeThickness: 8
  });
  map.entities.push(polyline);
};

export const removePolylines = () => {
  try {
    const map = window?.map;
    const Maps = window?.Microsoft?.Maps;
    if (map && Maps) {
      for (let i = map.entities.getLength() - 1; i >= 0; i--) {
        const polyline = map.entities.get(i);
        if (polyline instanceof Maps.Polyline) {
          map.entities.removeAt(i);
        }
      }
    }
  } catch (error) {
    console.log("remove poly  error", error);
  }
};

export const addNodePushpins = (updatedPushpins) => {
  const map = window.map;
  const Maps = window.Microsoft.Maps;

  const updatedPushPinsMap = updatedPushpins.reduce((acc, pushpin) => {
    acc[pushpin.options.title] = pushpin;
    return acc;
  }, {});

  let isInitialRender = true;
  for (let i = 0; i < map.entities.getLength(); i++) {
    const currentPushpin = map.entities.get(i);
    if (currentPushpin instanceof Maps.Pushpin) {
      if (isInitialRender) isInitialRender = false;

      const lat = Number(currentPushpin.geometry.y);
      const lon = Number(currentPushpin.geometry.x);
      currentPushpin.setLocation(new Maps.Location(lat, lon));

      const newPushpin = updatedPushPinsMap[currentPushpin.entity.title];
      if (
        newPushpin !== undefined &&
        newPushpin.options !== undefined &&
        newPushpin.options.icon !== undefined
      ) {
        currentPushpin.setOptions({ icon: newPushpin.options.icon });
      }
    }
  }

  if (isInitialRender) {
    for (const pushPin of updatedPushpins) {
      if (pushPin === null) {
        continue;
      }

      const newPushPin = new Maps.Pushpin(pushPin.center, pushPin.options);
      if (Object.prototype.hasOwnProperty.call(pushPin, "onClick")) {
        Maps.Events.addHandler(newPushPin, "click", () => pushPin.onClick());
      }
      map.entities.push(newPushPin);
    }
  }
};

export const createSitePushPin = (site, onClick) => {
  if (site !== undefined) {
    const siteStatusDict = siteStatuses[site.status] ?? siteStatuses.Ok;
    const color = siteStatusDict.color;
    const radius = siteStatusDict.pushpinRadiusPx;
    return {
      onClick,
      center: {
        latitude: site.details?.latAPI,
        longitude: site.details?.longAPI
      },
      options: {
        title: site.name,
        color,
        icon: SitePushPin(color, radius)
      }
    };
  } else {
    return {};
  }
};

const getNodeRadius = (ppm) => {
  return ppm < 2.1 ? 15 : ppm < 5 ? 20 : ppm < 20 ? 25 : 30;
};

const getNodeColor = (ppm) => {
  return ppm < 2.1 ? "none" : ppm < 5 ? "#ffd58b" : ppm < 20 ? "orange" : "red";
};

export const createNodePushPin = (node, nodePpmForSiteMap, showVOC) => {
  const nodeRead =
    nodePpmForSiteMap !== undefined &&
    nodePpmForSiteMap !== null &&
    nodePpmForSiteMap.reads !== undefined
      ? nodePpmForSiteMap.reads.find((item) => item.nodeId === node.NodeId)
      : undefined;
  const radius = nodeRead !== undefined ? getNodeRadius(nodeRead.ch4) : 15;
  const color = nodeRead !== undefined ? getNodeColor(nodeRead.ch4) : "none";
  const borderColor =
    nodePpmForSiteMap !== null &&
    nodePpmForSiteMap !== undefined &&
    node.NodeId === nodePpmForSiteMap.maxNodeId
      ? "black"
      : "white";
  const degree =
    nodeRead !== undefined ? polarToSvgRotation(nodeRead.windDIR) : -1;
  const windDIR =
    nodeRead !== undefined ? Number(nodeRead.windDIR).toFixed(1) : "-";
  const windMPH =
    nodeRead !== undefined ? Number(nodeRead.windMPH).toFixed(1) : "-";
  const ppm = nodeRead !== undefined ? Number(nodeRead.ch4).toFixed(1) : "-";
  const voc = nodeRead !== undefined ? Number(nodeRead.voc).toFixed(1) : "-";
  const vocRatio = nodeRead
    ? Number(nodeRead.voc / nodeRead.ch4).toFixed(1)
    : "-";
  const useWind = node?.useWind ?? true;
  return {
    center: {
      latitude: node.latitude,
      longitude: node.longitude
    },
    options: {
      title: node.name,
      icon: NodePushPin(
        color,
        borderColor,
        radius,
        degree,
        ppm,
        windMPH,
        windDIR,
        voc,
        vocRatio,
        showVOC,
        useWind
      )
    }
  };
};

export const zoomInSite = (coordinates, zoom = 18) => {
  const map = window.map;
  const Maps = window.Microsoft.Maps;
  const center = new Maps.Location(coordinates.latitude, coordinates.longitude);
  removePushPins();
  map.setView({
    center,
    zoom
  });
};

export const zoomInSites = (coordinates) => {
  const map = window.map;
  const Maps = window.Microsoft.Maps;
  if (coordinates.length > 1) {
    const boundingBox = Maps.LocationRect.fromLocations(coordinates);
    // Calculate the center of the bounding box
    const center = boundingBox.center;
    // Set the center and zoom level to fit the bounding box in the map
    map.setView({
      center,
      bounds: boundingBox
    });
  } else if (coordinates.length === 1) {
    zoomInSite(coordinates[0]);
  }
};

export const handleToggleDroneImageLayer = (newValue, overlayDetails) => {
  if (newValue) {
    topographicOverlay([overlayDetails]);
  } else {
    removeOverlay(overlayDetails.imageDetails.SiteId);
  }
};
