import { FC } from "react";
import { Node } from "../../store/nodes/types";
import { NodePPMProps, NodeColors } from "./types";
import NodePPMChart from "./NodePPMChart/NodePPMChart";

const plotColors = [
  "#d30808", // red
  "#0a902a", // green
  "#d27a36", // orange
  "#2478b3", // blue
  "#7b3a92", // purple
  "#2ac4af", // teal
  "#b7a61d", // yellow
  "#5e5a54", // grey
  "#5a4b20", // brown
];

const NodePPM: FC<NodePPMProps> = ({
  nodePPM,
  nodes,
  selectedNodeIds,
  timezone,
  resetChartKey,
  chartTimerange,
  handleSetChartTimerange,
  nodeSamplesDisplay,
}) => {
  const nodeColors = nodes.reduce(
    (result: NodeColors, node: Node, idx: number) => {
      const color = plotColors[idx % plotColors.length];
      result[node.NodeId] = `${color}`;
      return result;
    },
    {}
  );

  const selectedNodes: Node[] = nodes.filter((node) =>
    selectedNodeIds.includes(node.NodeId)
  );

  return (
    <div style={{ width: "99%" }}>
      <NodePPMChart
        timezone={timezone}
        data={nodePPM}
        selectedNodes={selectedNodes}
        nodeColors={nodeColors}
        resetChartKey={resetChartKey}
        chartTimerange={chartTimerange}
        handleSetChartTimerange={handleSetChartTimerange}
        nodeSamplesDisplay={nodeSamplesDisplay}
      />
    </div>
  );
};

export default NodePPM;
