import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import {
  BusinessUnit,
  CreateBusinessUnitPayload,
  CreateBusinessUnitResult,
  DeleteSiteTagPayload,
  DeleteSiteTagResponse,
  GetBusinessUnitSitesResult,
  GetBusinessUnitsResult,
  UpdateBusinessUnitSitesPayload,
  UpdateBusinessUnitSitesResponse,
  UpdateSiteTagResponse,
} from "./types";

export const businessUnitsApi = createApi({
  reducerPath: "businessUnitsApi",
  baseQuery,
  tagTypes: ["BusinessUnits", "BusinessUnitsSites"],
  endpoints: (builder) => ({
    getBusinessUnits: builder.query<GetBusinessUnitsResult, string>({
      query: (orgId) => `organization/${orgId}/businessUnits`,
      providesTags: ["BusinessUnits"],
    }),

    getBusinessUnitSites: builder.query<GetBusinessUnitSitesResult, string>({
      query: (id) => `businessUnits/${id}/sites`,
      providesTags: ["BusinessUnitsSites"],
    }),

    createBusinessUnit: builder.mutation<
    CreateBusinessUnitResult,
    CreateBusinessUnitPayload
    >({
      query: (payload) => ({
        url: `organization/${payload.orgId}/newBusinessUnit`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["BusinessUnits"],
    }),

    updateBusinessUnitSites: builder.mutation<
    UpdateBusinessUnitSitesResponse,
    UpdateBusinessUnitSitesPayload
    >({
      query: (payload) => ({
        url: `updateBusinessUnitSites/${payload.tagId}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["BusinessUnits", "BusinessUnitsSites"],
    }),

    updateBusinessUnit: builder.mutation<
    UpdateSiteTagResponse,
    BusinessUnit
    >({
      query: (payload) => ({
        url: `updateBusinessUnit/${payload.tagId}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["BusinessUnits"],
    }),

    deleteBusinessUnit: builder.mutation<
    DeleteSiteTagResponse,
    DeleteSiteTagPayload
    >({
      query: (payload) => ({
        url: `businessUnit/${payload.tagId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BusinessUnits"],
    }),
  }),
});

export const {
  useGetBusinessUnitsQuery,
  useGetBusinessUnitSitesQuery,
  useCreateBusinessUnitMutation,
  useUpdateBusinessUnitSitesMutation,
  useUpdateBusinessUnitMutation,
  useDeleteBusinessUnitMutation,
} = businessUnitsApi;
