import { FC } from "react";
import { Tabs } from "antd";
import Sites from "../Sites/Sites";
import BusinessUnits from "../BusinessUnits/BusinessUnits";

const OrganizationManagement: FC = () => {
  const tabItems = [
    {
      key: "sites",
      label: "Sites",
      children: <Sites />,
    },
    {
      key: "business-units",
      label: "Business Units",
      children: <BusinessUnits />,
    },
  ];

  return <Tabs defaultActiveKey="users" items={tabItems} />;
};

export default OrganizationManagement;
