export const onPrintQRCodes = (qrCodes: string[], selectedRowKeys: string[]): void => {
  const iframe = document.createElement("iframe");
  iframe.style.display = "none";
  document.body.appendChild(iframe);
  const iframeDoc = iframe.contentWindow?.document;
  if (iframeDoc != null) {
    iframeDoc.body.style.width = "6in";
    iframeDoc.body.style.height = "4in";
    const wrapper = iframeDoc.createElement("div");
    qrCodes.forEach((code, index): void => {
      const qrCodeDiv = iframeDoc.createElement("div");
      qrCodeDiv.style.display = "block";
      qrCodeDiv.style.flexDirection = "column";
      qrCodeDiv.style.justifyContent = "center";
      qrCodeDiv.style.alignItems = "center";
      qrCodeDiv.style.textAlign = "center";
      qrCodeDiv.style.margin = "auto";
      qrCodeDiv.style.width = "100%";
      const img = document.createElement("img");
      img.src = code;
      img.style.height = "3in";
      img.style.width = "3in";
      img.style.margin = "auto";
      const label = document.createElement("span");
      label.textContent = selectedRowKeys[index];
      label.style.display = "block";
      label.style.fontWeight = "bold";
      label.style.fontSize = "27px";
      label.style.padding = "5px";
      label.style.lineHeight = "1.3";
      label.style.overflow = "visible";
      label.style.height = "auto";
      label.style.fontFamily = "Arial, sans-serif";
      qrCodeDiv.appendChild(img);
      qrCodeDiv.appendChild(label);
      const pageBreak = document.createElement("div");
      pageBreak.style.pageBreakAfter = "always";
      qrCodeDiv.appendChild(pageBreak);
      wrapper.appendChild(qrCodeDiv);
    });
    iframeDoc.body.appendChild(wrapper);
    if (iframe.contentWindow !== null && iframe.contentWindow !== undefined) {
      iframe.contentWindow.print();
    }
  }
};
