import { FC, useState, useEffect } from "react";
import { useAppSelector } from "../../store/hooks";
import { TimestreamNodePPMParams } from "../../store/timestreamNodePPM/types";
import { currentSiteSelector } from "../../store/sites/selector";
import NodePPM from "../../components/NodePPM/NodePPM";
import { timestreamNodePPMApi } from "../../store/timestreamNodePPM/api";
import { NodeParams } from "../../store/nodes/types";
import { siteNodesApi } from "../../store/nodes/api";
import { Site } from "../../store/sites/types";
import { QuantityUnit } from "../../helpers/unitsHelpers";
import { unitOptions, STATS_TYPES } from "./types";
import {
  startTimestampSelector,
  endTimestampSelector,
} from "../../store/selections/selector";
import { getDateString } from "../../helpers/dayjsHelpers";
import SiteStatsSelection from "./SelectionComponent/SiteStatsSelection";
import AverageEmissionRates from "../../components/AverageEmissionRates/AverageEmissionRates";
import { siteBaselinesApi } from "../../store/siteBaselines/api";

const REFRESH_TIME_MS = 5 * 60 * 1000;
const MAX_NUM_NODES = 6;

const SiteStats: FC = () => {
  const [showLocalTime, setShowLocalTime] = useState(true);
  const [selectedStat, setSelectedStat] = useState(
    STATS_TYPES.AvgEmissionRates
  );
  const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>([]);
  const startTimestamp = useAppSelector(startTimestampSelector);
  const endTimestamp = useAppSelector(endTimestampSelector);
  const [chartTimerange, setChartTimerange] = useState({
    startTimestamp,
    endTimestamp,
  });
  const [epaBaselineDisplay, setEpaBaselineDisplay] = useState({
    doShow90Day: false,
    doShow7Day: false,
    doShowSiteBaseline: false,
  });
  const [nodeSamplesDisplay, setShowNodeSamplesDisplay] = useState({
    doShowCH4: true,
    doShowVOC: false,
    doShowCO2: false,
  });
  const [resetChartKey, setResetChartKey] = useState(0);
  const currentSite: Site | null = useAppSelector(currentSiteSelector);
  const [triggerGetTimestreamNodePPM, timestreamNodePPMQueryResult] =
    timestreamNodePPMApi.endpoints.getTimestreamNodePPM.useLazyQuery();

  const siteId = currentSite?.SiteId;
  const timezone = currentSite?.timezone;
  const siteName = currentSite?.name;
  const id = siteId ?? "";
  const timezoneLabel = timezone ?? "";
  const name = siteName ?? "";

  const initialUnitSelection =
    currentSite?.config.emissionUnits ?? QuantityUnit.KG;
  const siteBaselineResponse =
    siteBaselinesApi.endpoints.getActiveSiteBaseline.useQuery(id, {
      skip: id === "",
    });
  const siteBaseline = siteBaselineResponse?.data?.result ?? null;
  const doEnableEpasiteBaseline = siteBaseline !== null;
  const [selectedUnitOption, setSelectedUnitOption] =
    useState(initialUnitSelection);

  const statTypeOptions = [
    {
      label: "Avg Emission Rates",
      value: STATS_TYPES.AvgEmissionRates,
    },
    {
      label: "Node Samples",
      value: STATS_TYPES.NodeSamples,
    },
  ];

  const nodesPayload: NodeParams = {
    id,
  };

  const resultSiteNodes = siteNodesApi.endpoints.getSiteNodes.useQuery(
    nodesPayload,
    {
      skip: selectedStat === STATS_TYPES.AvgEmissionRates,
    }
  );

  const siteNodes = resultSiteNodes?.data?.result ?? [];
  const siteNodeOptions = siteNodes.map((x) => {
    return {
      name: x.name + (x.active ? "" : "(Inactive)"),
      id: x.NodeId,
      active: x.active,
    };
  });
  const sortedSiteNodeOptions = siteNodeOptions.sort((a, b) => {
    if (a.active && !b.active) return -1;
    if (!a.active && b.active) return 1;

    const labelA = a.name !== undefined ? a.name : "";
    const labelB = b.name !== undefined ? b.name : "";

    return labelA.localeCompare(labelB);
  });

  const nodePPM = timestreamNodePPMQueryResult?.data?.result ?? [];
  const isFetching = timestreamNodePPMQueryResult?.isFetching ?? true;

  const handleResetChart = (): void => {
    setResetChartKey(resetChartKey + 1);
  };

  const handleStatChange = (newVal: string): void => {
    setSelectedStat(newVal);
  };

  const onNodeChange = (newNodes: string[]): void => {
    setSelectedNodeIds(newNodes);
  };

  const handleChangeShowLocalTime = (newValue: boolean): void => {
    setShowLocalTime(newValue);
  };

  const onUnitOptionChange = (newUnit: QuantityUnit): void => {
    setSelectedUnitOption(newUnit);
  };

  const handleSetChartTimerange = (
    startTimestamp: string,
    endTimestamp: string
  ): void => {
    setChartTimerange({ startTimestamp, endTimestamp });
  };

  const refreshData = async (): Promise<void> => {
    document.body.style.cursor = "wait";

    const timestreamNodePPMPayload: TimestreamNodePPMParams = {
      startDate: chartTimerange.startTimestamp,
      endDate: chartTimerange.endTimestamp,
      siteId: id,
      nodeIds: selectedNodeIds.join(","),
      timezone: showLocalTime ? timezone : "UTC",
    };

    if (selectedNodeIds.length > 0) {
      await triggerGetTimestreamNodePPM(timestreamNodePPMPayload);
    }

    document.body.style.cursor = "default";
  };

  useEffect(() => {
    return () => {
      document.body.style.cursor = "default";
    };
  }, []);

  useEffect(() => {
    if (selectedStat === STATS_TYPES.AvgEmissionRates) {
      return;
    }

    refreshData().catch((e) => {
      console.log("Error fetching data:", e);
    });

    // Automatic refresh
    const currentDate = getDateString();
    const [selectedDate] = startTimestamp.split(" ");
    if (currentDate === selectedDate) {
      const interval = setInterval(() => {
        refreshData().catch((e) => {
          console.log("Error fetching data:", e);
        });
      }, REFRESH_TIME_MS);
      return () => clearInterval(interval);
    }
  }, [selectedNodeIds, chartTimerange, showLocalTime]);

  useEffect(() => {
    setSelectedNodeIds(
      sortedSiteNodeOptions
        .filter((node) => node.active)
        .map((node) => node.id)
        .slice(0, MAX_NUM_NODES)
    );
  }, [resultSiteNodes?.data?.result]);

  return (
    <div style={{ overflow: "hidden" }}>
      <div>
        <SiteStatsSelection
          selectedStat={selectedStat}
          statTypeOptions={statTypeOptions}
          handleStatChange={handleStatChange}
          disabled={isFetching || resultSiteNodes.isFetching}
          onNodeChange={onNodeChange}
          siteNodeOptions={sortedSiteNodeOptions}
          selectedNodeIds={selectedNodeIds}
          unitOptions={unitOptions}
          selectedUnitOption={selectedUnitOption}
          onUnitOptionChange={onUnitOptionChange}
          refreshData={refreshData}
          showLocalTime={showLocalTime}
          handleChangeShowLocalTime={handleChangeShowLocalTime}
          handleResetChartKey={handleResetChart}
          handleSetChartTimerange={handleSetChartTimerange}
          doEnableEpasiteBaseline={doEnableEpasiteBaseline}
          setEpaBaselineDisplay={setEpaBaselineDisplay}
          nodeSamplesDisplay={nodeSamplesDisplay}
          setNodeSamplesDisplay={setShowNodeSamplesDisplay}
        />
        {selectedStat === STATS_TYPES.AvgEmissionRates ? (
          <AverageEmissionRates
            siteId={id}
            siteName={name}
            selectedUnits={selectedUnitOption}
            timezone={timezone}
            resetChartKey={resetChartKey}
            chartTimerange={chartTimerange}
            handleSetChartTimerange={handleSetChartTimerange}
            siteBaseline={siteBaseline}
            epaBaselineDisplay={epaBaselineDisplay}
          />
        ) : (
          <NodePPM
            nodePPM={nodePPM}
            nodes={siteNodes}
            selectedNodeIds={selectedNodeIds}
            siteNodeOptions={sortedSiteNodeOptions}
            timezone={showLocalTime ? timezoneLabel : "UTC"}
            resetChartKey={resetChartKey}
            chartTimerange={chartTimerange}
            handleSetChartTimerange={handleSetChartTimerange}
            nodeSamplesDisplay={nodeSamplesDisplay}
          />
        )}
      </div>
    </div>
  );
};

export default SiteStats;
