import { FC, useState, useEffect } from "react";
import "chartjs-adapter-moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  TooltipPositionerFunction,
  Legend,
  TimeScale,
  Plugin,
  ChartType,
} from "chart.js";
import { ActiveElement } from "chart.js/dist/plugins/plugin.tooltip";
import { Line } from "react-chartjs-2";
import {
  NodePPMChartProps,
  TooltipContext,
  emptyTimestreamNodePPMItem,
} from "../types";
import styles from "../styles.module.scss";
import {
  TimeSeriesUnit,
  NodeSamplesStatType,
} from "../../../pages/SiteStats/types";
import zoomPlugin from "chartjs-plugin-zoom";
import { TimestreamNodePPM } from "../../../store/timestreamNodePPM/types";
import { getTimestampFromMilliseconds } from "../../../helpers/dayjsHelpers";
import dayjs from "dayjs";
import { nodeSamplesDisplayToSelectedStatTypes } from "../../../pages/SiteStats/helpers";
import {
  makeChartTitle,
  makeChartObjects,
  makeChartElements,
  makeChartData,
} from "./helpers";
import type { ChartScale } from "./types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  zoomPlugin
);

declare module "chart.js" {
  interface TooltipPositionerMap {
    customPositioner: TooltipPositionerFunction<ChartType>;
  }
}

const customPositioner = (
  elements: Readonly<ActiveElement[]>,
  eventPosition: { x: number }
): { x: number; y: number } => {
  let { x } = eventPosition;
  const y = 1e8; // large value pushes to bottom

  x += tooltipOffset;

  return { x, y };
};

Tooltip.positioners.customPositioner = customPositioner;

const UNIT_HOURS_THRESHOLD = 48;

const CHART_Y_LIMITS = {
  [NodeSamplesStatType.CH4]: {
    MIN: 1.5,
    SUGGESTED_MAX: 5.0,
  },
  [NodeSamplesStatType.VOC]: {
    MIN: 0.0,
    SUGGESTED_MAX: 1.0,
  },
  [NodeSamplesStatType.CO2]: {
    MIN: 0.0,
    SUGGESTED_MAX: 1000.0,
  },
};

const canvasElement = document.getElementById("chart-site-stats");
const chartInstance = ChartJS.getChart(canvasElement as HTMLCanvasElement);
const tooltipOffset = chartInstance?.tooltip?.width ?? 200;

const NodePPMChart: FC<NodePPMChartProps> = ({
  timezone,
  data,
  selectedNodes,
  nodeColors,
  resetChartKey,
  chartTimerange,
  handleSetChartTimerange,
  nodeSamplesDisplay,
}) => {
  const chart = ChartJS.getChart("chart-site-stats");
  const [xRange, setXRange] = useState({
    min: new Date(chartTimerange.startTimestamp).getTime(),
    max: new Date(chartTimerange.endTimestamp).getTime(),
  });

  const chartElements = makeChartElements(selectedNodes, nodeColors);
  const chartObjects = makeChartObjects(data, chartElements);
  const { sourceData } = chartObjects;

  const handleZoom = (chart: ChartJS): void => {
    const { min, max } = chart.scales.x;
    setXRange({ min, max });

    const newStartTimestamp = getTimestampFromMilliseconds(min, timezone);
    const newEndTimestamp = getTimestampFromMilliseconds(max, timezone);
    handleSetChartTimerange(newStartTimestamp, newEndTimestamp);
  };

  const getToolTipData = (dataIndex: number): TimestreamNodePPM[] => {
    const dataEle = sourceData[dataIndex];
    const nodesData = sourceData.filter(
      (item) => item.Timestamp === dataEle.Timestamp
    );

    const reducedNodesData: TimestreamNodePPM[] = Object.values(
      nodesData.reduce<{ [key: string]: TimestreamNodePPM }>((acc, obj) => {
        const nodeId = obj.NodeId;
        const isNewNode = (acc[nodeId] ?? null) === null;

        const currentCh4Value = obj.gas1D;
        const maxCh4Value = acc[nodeId]?.gas1D ?? "";

        const currentVocValue = obj?.gas2D ?? "";
        const maxVocValue = acc[nodeId]?.gas2D ?? "";

        if (isNewNode || maxCh4Value < currentCh4Value) {
          acc[nodeId] = {
            ...obj,
            gas1D: currentCh4Value,
            gas2D: acc[nodeId]?.gas2D ?? "",
          };
        }

        if (isNewNode || maxVocValue < currentVocValue) {
          acc[nodeId] = {
            ...obj,
            gas1D: acc[nodeId].gas1D,
            gas2D: currentVocValue,
          };
        }

        return acc;
      }, {})
    );

    const nodesDataIds = reducedNodesData.map(
      (item: TimestreamNodePPM) => item.NodeId
    );

    const orderedNodeIds = selectedNodes.map((node) => node.NodeId);
    orderedNodeIds.forEach((nodeId) => {
      if (!nodesDataIds.includes(nodeId)) {
        reducedNodesData.push({
          ...emptyTimestreamNodePPMItem,
          NodeId: nodeId,
        });
      }
    });

    const result = reducedNodesData.sort((a, b) => {
      return (
        orderedNodeIds.indexOf(a.NodeId) - orderedNodeIds.indexOf(b.NodeId)
      );
    });

    return result;
  };

  let timeUnit = "day";
  if (chart != null) {
    const min = dayjs(chartTimerange.startTimestamp);
    const max = dayjs(chartTimerange.endTimestamp);
    const timeRangeHours = max.diff(min, "hour");
    timeUnit = timeRangeHours <= UNIT_HOURS_THRESHOLD ? "hour" : "day";
  }

  const tooltipCallback = (context: TooltipContext): string[] => {
    // console.log("callback")
    const isSingleQuant =
      nodeSamplesDisplay.doShowCH4 ||
      nodeSamplesDisplay.doShowVOC ||
      nodeSamplesDisplay.doShowCO2;
    const isVoc = context.dataset.label?.includes("VOC") ?? false;

    if (
      (!(nodeSamplesDisplay.doShowCH4 && nodeSamplesDisplay.doShowVOC) &&
        isSingleQuant) ||
      (nodeSamplesDisplay.doShowCH4 && nodeSamplesDisplay.doShowVOC && isVoc)
    ) {
      const dataIndex = context.dataIndex;
      const nodesData = getToolTipData(dataIndex);

      const displayTextArr = nodesData.map((item) => {
        let name =
          selectedNodes.find((node) => node.NodeId === item.NodeId)?.name ?? "";
        if (name.length > 10) {
          name = name.slice(0, 10) + "...";
        }

        const ch4Value =
          item.gas1D !== undefined && item.gas1D > 0
            ? Number(item.gas1D).toFixed(1)
            : "--";

        const vocValue =
          item.gas2D !== undefined && item.gas2D > 0
            ? Number(item.gas2D).toFixed(1)
            : "--";

        const co2Value =
          item.co2 !== undefined && item.co2 > 0
            ? Number(item.co2).toFixed(1)
            : "--";

        const ch4Text = ch4Value + " ppm";
        const vocText = vocValue + " ppm";
        const ch4VocText = ch4Value + " CH4 " + vocValue + " VOC ppm";
        const co2Text = co2Value + " ppm";

        if (nodeSamplesDisplay.doShowCH4 && nodeSamplesDisplay.doShowVOC) {
          return name + "   " + ch4VocText;
        } else if (nodeSamplesDisplay.doShowCH4) {
          return name + "   " + ch4Text;
        } else if (nodeSamplesDisplay.doShowVOC) {
          return name + "   " + vocText;
        } else if (nodeSamplesDisplay.doShowCO2) {
          return name + "   " + co2Text;
        } else return "";
      });

      return displayTextArr;
    } else {
      return [];
    }
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false as const,
    spanGaps: true as const,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    plugins: {
      legend: {
        position: "top" as const,
      },
      tooltip: {
        mode: "index" as const,
        caretSize: 0,
        displayColors: false,
        position: "customPositioner" as const,
        backgroundColor: "rgba(0,0,0)" as const,
        align: "start",
        callbacks: {
          label: tooltipCallback,
        },
      },
      title: {
        display: true,
        text: makeChartTitle(nodeSamplesDisplay),
        font: {
          size: 24,
          weight: "bold" as const,
        },
      },
      datalabels: {
        display: true,
        align: "center",
        color: "black",
      },
      zoom: {
        zoom: {
          mode: "x" as const,
          drag: {
            enabled: true,
          },
          onZoomComplete: ({ chart }: { chart: ChartJS }) => handleZoom(chart),
        },
      },
    },
    scales: {
      x: {
        type: "time" as const,
        time: {
          unit: timeUnit as TimeSeriesUnit,
        },
        title: {
          display: true,
          text: `Timestamp (${timezone})`,
          font: {
            size: 20,
            weight: "bold" as const,
          },
        },
        grid: {
          drawOnChartArea: false,
        },
        min: xRange.min,
        max: xRange.max,
      },
    },
  };

  let axisCount = 0;
  const selectedGasTypes =
    nodeSamplesDisplayToSelectedStatTypes(nodeSamplesDisplay);
  for (const gasType of selectedGasTypes) {
    (options.scales as ChartScale)[gasType as keyof ChartScale] = {
      type: "linear" as const,
      position: axisCount % 2 === 1 ? "right" : "left",
      title: {
        display: true,
        text: gasType + " (PPM)",
        font: {
          size: 20,
          weight: "bold",
        },
      },
      grid: {
        drawOnChartArea: axisCount === 0,
      },
      min: CHART_Y_LIMITS[gasType].MIN,
      suggestedMax: CHART_Y_LIMITS[gasType].SUGGESTED_MAX,
    };

    ++axisCount;
  }

  const plugins: Plugin[] = [
    {
      id: "tooltipLine",
      afterDraw: (chart: ChartJS) => {
        const firstYAxisScaleKey = Object.keys(chart.scales)[1];
        if (
          chart?.tooltip !== null &&
          chart.tooltip?.caretX !== undefined &&
          chart.scales[firstYAxisScaleKey] !== undefined
        ) {
          const { ctx } = chart;
          const { caretX } = chart.tooltip;

          const topY = chart.scales[firstYAxisScaleKey].top;
          const bottomY = chart.scales[firstYAxisScaleKey].bottom;

          ctx.save();
          ctx.beginPath();
          ctx.moveTo(caretX - tooltipOffset, topY);
          ctx.lineTo(caretX - tooltipOffset, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = "#000000";
          ctx.stroke();
          ctx.restore();
        }
      },
    },
  ];

  const chartData = makeChartData(
    nodeSamplesDisplay,
    chartElements,
    chartObjects
  );

  useEffect(() => {
    setXRange({
      min: new Date(chartTimerange.startTimestamp).getTime(),
      max: new Date(chartTimerange.endTimestamp).getTime(),
    });
  }, [resetChartKey, data]);

  return (
    <div className={styles.ChartWrapper}>
      <Line
        id={"chart-site-stats"}
        options={options}
        data={chartData}
        plugins={plugins}
      />
    </div>
  );
};

export default NodePPMChart;
