import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../BaseAPI/baseQuery";
import {
  GPSVehicleResponse,
  GPSVehicleRouteResponse,
  GPSVehicleRouteParams,
} from "./types";

export const gpsTrackitApi = createApi({
  reducerPath: "gpsTransitApi",
  baseQuery,
  tagTypes: ["Vehicles", "VehicleRoutes"],
  endpoints: (builder) => ({
    getVehicles: builder.query<GPSVehicleResponse, null>({
      query: () => {
        return {
          url: "/gpsTrackit/vehicles",
          method: "GET",
        };
      },
      providesTags: ["Vehicles"],
    }),
    getVehicleRoute: builder.query<
    GPSVehicleRouteResponse,
    GPSVehicleRouteParams
    >({
      query: (payload) => {
        const { id, date } = payload;
        const params = { date };
        return {
          url: `/gpsTrackit/vehicles/${id}/route`,
          method: "GET",
          params,
        };
      },
      providesTags: ["VehicleRoutes"],
    }),
  }),
});

export const { useGetVehiclesQuery, useGetVehicleRouteQuery } = gpsTrackitApi;
