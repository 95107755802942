import { FC, useEffect, cloneElement } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { authApi } from "../../store/auth/api";
import { useNavigate } from "react-router-dom";
import { setCurrentUser } from "../../store/auth/slice";
import {
  LogoutOutlined,
  TeamOutlined,
  LineChartOutlined,
  BarChartOutlined,
  FundViewOutlined,
  HomeOutlined,
  BellOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { OilPumpIcon, DeviceWarningIcon, BusinessBuildingIcon } from "./icons";
import { organizationsApi } from "../../store/organizations/api";
import Logo from "../../assets/ev_logo.png";
import styles from "./style.module.scss";
import { LayoutProps, MenuIconProps } from "./types";
import { User } from "../../store/users/types";
import { currentSiteSelector } from "../../store/sites/selector";
import { currentOrganizationSelector } from "../../store/organizations/selector";
import { setCurrentOrganization } from "../../store/organizations/slice";
import { Organization } from "../../store/organizations/types";
import {
  setShowSideNavigation,
  setShowMapController,
} from "../../store/orgMap/slice";
import { currentTabSelector } from "../../store/selections/selector";
import {
  setCurrentTab,
  setEndTimestamp,
  setStartTimestamp,
} from "../../store/selections/slice";
import {
  addPushpins,
  createSitePushPin,
  zoomInSites,
  zoomInSite,
  removePolylines,
} from "../../pages/OrganizationMap/mapApi";
import { AlarmIncidentParams } from "../../store/alarmIncidents/types";
import { customerAlarmIncidentApi } from "../../store/alarmIncidents/api";
import { Button, Tooltip } from "antd";
import { Site } from "../../store/sites/types";
import { setCurrentSite } from "../../store/sites/slice";
import { setSideBarElements } from "../../store/ui/slice";
import { SideBarElement } from "../../store/ui/types";
import { Coordinates } from "../MapController/types";
import {
  getStartOfTodayString,
  getStartOfTomorrowString,
} from "../../helpers/dayjsHelpers";
import { removeMovableOverlay } from "../../pages/OrganizationMap/imageOverlayHelper";

export const [defaultLat, defaultLon] = [38.049518, -94.852874]; // centered on US

export const ANTD_BLUE = "#1677ff";

const localStyles = {
  Button: { height: 35, width: 35 },
  Icon: { fontSize: 15 },
};

const LayoutV2: FC<LayoutProps> = ({ children, signOut, userId }) => {
  const dispatch = useAppDispatch();
  const selectedPage = useAppSelector(currentTabSelector);
  const currentSite = useAppSelector(currentSiteSelector);
  const currentOrg = useAppSelector(currentOrganizationSelector);
  const navigate = useNavigate();
  const [getCurrentUserTrigger, result] =
    authApi.endpoints.getCurrentUser.useLazyQuery();
  const [customerAlarmIncidentsTrigger] =
    customerAlarmIncidentApi.endpoints.getCustomerAlarmIncidents.useLazyQuery();
  const [getOrganizationsTrigger] =
    organizationsApi.endpoints.getOrganizations.useLazyQuery();

  const currentSiteName = currentSite?.name;
  const currentOrgName = currentOrg?.name;
  const currentSiteId = currentSite?.SiteId ?? "";

  let isAdminUser = false;
  let userName = "";
  let email = "";

  if (userId !== "" && result?.data !== undefined) {
    const { data } = result;
    isAdminUser = data.isAdmin;
    userName = data.userName;
    email = data.email;
  }

  let orgSiteTextDisplay = "";
  if (currentOrgName !== undefined) {
    orgSiteTextDisplay += currentOrgName;
    if (currentSiteName !== undefined) {
      orgSiteTextDisplay += " - ";
      orgSiteTextDisplay += currentSiteName;
    }
  }

  const handleSignOut = (): void => {
    dispatch(setCurrentUser({}));
    navigate("/");
    signOut();
    localStorage.removeItem("persist:root");
    localStorage.clear();
  };

  const handleSetSideBarElements = (elements: SideBarElement[]): void => {
    const sorted = elements.sort((a, b) => {
      const aIsActive = a.isActive !== null && a.isActive === true;
      const bIsActive = b.isActive !== null && b.isActive === true;
      if (aIsActive && !bIsActive) return -1;
      if (!aIsActive && bIsActive) return 1;

      return a.title.localeCompare(b.title);
    });

    dispatch(setSideBarElements(sorted));
  };

  const handleGetOrganizations = async (): Promise<Organization[]> => {
    return await getOrganizationsTrigger(null)
      .unwrap()
      .then((response) => {
        const sideBarElements = response.map((item) => {
          return {
            title: item.name,
            subtitle: item.country,
            onClick: "selectOrganization",
            id: item.OrgId,
            organization: item,
            description: "",
          };
        });
        handleSetSideBarElements(sideBarElements);

        return response;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      });
  };

  const sitePushPinCallback = (site: Site) => {
    return () => {
      dispatch(setShowSideNavigation(false));
      dispatch(setShowMapController(true));
      dispatch(setCurrentSite(site));
      zoomInSite({
        latitude: site?.details?.latAPI,
        longitude: site?.details?.longAPI,
      });
    };
  };

  const handleGetCustomerSites = (orgIds: string): void => {
    const params: AlarmIncidentParams = { ids: orgIds };
    customerAlarmIncidentsTrigger(params)
      .unwrap()
      .then((res) => {
        const sideBarElements = [];
        const coordinates: Coordinates[] = [];
        for (const org of res) {
          for (const element of org.sites) {
            sideBarElements.push({
              site: element,
              title: element.name,
              subtitle: org.country,
              onClick: "selectSite",
              id: element.SiteId,
              description: "",
              organization: {
                OrgId: org.OrgId,
                name: org.orgName,
                country: org.country,
              },
              isActive: element.active,
            });
          }
        }
        for (const sideBarItem of sideBarElements) {
          if (sideBarItem.site?.details !== undefined) {
            coordinates.push({
              latitude: parseFloat(sideBarItem.site.details.latAPI),
              longitude: parseFloat(sideBarItem.site.details.longAPI),
            });
          }
        }

        const sitesPushPins = sideBarElements.map((sideBarElement) => {
          const site = sideBarElement.site;
          return createSitePushPin(site, sitePushPinCallback(site as Site));
        });
        handleSetSideBarElements(sideBarElements);

        const search = window.location.search;
        const sParams = new URLSearchParams(search);
        const paramsSiteId = sParams.get("siteId");
        if (paramsSiteId !== null) {
          const _site = sideBarElements.find(
            (element) => element.site.SiteId === paramsSiteId
          );
          if (_site?.site.details !== undefined) {
            dispatch(setShowMapController(true));
            zoomInSites([
              {
                latitude: parseFloat(_site.site.details.latAPI),
                longitude: parseFloat(_site.site.details.longAPI),
              },
            ]);
            dispatch(setCurrentSite(_site?.site));
            dispatch(setShowSideNavigation(false));
          } else {
            addPushpins(sitesPushPins);
            zoomInSites(coordinates);
          }
        } else {
          addPushpins(sitesPushPins);
          zoomInSites(coordinates);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleAdminSignIn = async (): Promise<void> => {
    const orgs = await handleGetOrganizations();
    const search = window.location.search;
    const sParams = new URLSearchParams(search);
    const paramsOrgId = sParams.get("orgId");
    if (paramsOrgId != null && orgs != null) {
      const newOrg = orgs.find((org) => org.OrgId === paramsOrgId);
      if (newOrg != null) {
        dispatch(setCurrentOrganization(newOrg));
      }
      handleGetCustomerSites(paramsOrgId);
      dispatch(setShowSideNavigation(false));
    }
  };

  const getCurrentUserDetails = (): void => {
    getCurrentUserTrigger(null)
      .unwrap()
      .then(async (res: User) => {
        const organizations = res.organizations;
        for (const org of organizations) {
          // remove this when string[] is removed from User.organizations
          if (typeof org === "string") return;
        }
        const orgIds = (organizations as Organization[]).map(
          (org: Organization) => String(org.OrgId)
        );
        const orgIdsString = orgIds.join(",");
        if (res.isAdmin) {
          await handleAdminSignIn();
        } else {
          handleGetCustomerSites(orgIdsString);
        }
      })
      .catch((e) => console.log(e));
  };

  const alertNoSiteSelected = (): void => {
    window.alert("no site has been selected");
  };

  const navigateToMap = async (): Promise<void> => {
    dispatch(setCurrentSite(null));
    dispatch(setShowSideNavigation(true));
    dispatch(setCurrentTab("Home"));
    removeMovableOverlay();
    handleNavigate("/");

    const getSitesResponse = await customerAlarmIncidentsTrigger({
      ids: currentOrg?.OrgId ?? "",
    });
    let sites: Site[] = [];
    if (getSitesResponse.data !== undefined) {
      sites = getSitesResponse.data[0].sites;
    }
    const coordinates = sites.map((site: Site) => {
      return {
        latitude: site?.details?.latAPI,
        longitude: site?.details?.longAPI,
      };
    });
    const sitesPushPins = sites.map((site) => {
      return createSitePushPin(site, sitePushPinCallback(site));
    });

    addPushpins(sitesPushPins);
    zoomInSites(coordinates);
  };

  const navigateToSite = (): void => {
    if (currentSite == null) {
      window.alert("No site selected");
      return;
    }
    dispatch(setCurrentTab("Site Map"));
    dispatch(setShowMapController(true));
    handleNavigate("/");
    const defaultLatLon = { latAPI: defaultLat, longAPI: defaultLon };
    const { latAPI, longAPI } = currentSite?.details ?? defaultLatLon;
    zoomInSite({
      latitude: latAPI,
      longitude: longAPI,
    });
  };

  const navigateToEmissions = (): void => {
    if (currentSiteId === undefined || currentSiteId === "") {
      window.alert("No site selected");
      return;
    }
    dispatch(setCurrentTab("Emissions"));
    navigate(`sites/${currentSiteId}/emissions`);
  };

  const navigateToSiteData = (): void => {
    if (currentSiteId === undefined || currentSiteId === "") {
      window.alert("No site selected");
      return;
    }
    const newStartDate = getStartOfTodayString();
    const newEndDate = getStartOfTomorrowString();
    dispatch(setStartTimestamp(newStartDate));
    dispatch(setEndTimestamp(newEndDate));
    dispatch(setCurrentTab("Site Data"));
    navigate(`sites/${currentSiteId}`);
  };

  const handleNavigate = (route: string, showSiteError = false): void => {
    if (currentSite === null && showSiteError) {
      alertNoSiteSelected();
    } else {
      navigate(route);
    }
  };

  useEffect(() => {
    if (userId !== "") {
      getCurrentUserDetails();
    }
  }, [userId]);

  useEffect(() => {
    if (currentSite?.details !== undefined && selectedPage === "Home") {
      dispatch(setCurrentTab("Site Map"));
    }
  }, [currentSite]);

  useEffect(() => {
    removePolylines();
  }, [selectedPage]);

  const MenuIcon = ({
    Icon,
    title,
    route = "",
    onClick,
  }: MenuIconProps): JSX.Element => {
    const style = { color: selectedPage === title ? ANTD_BLUE : "black" };
    const onClickDiv = (): void => {
      if (route !== "") handleNavigate(`/${route}`);
      if (onClick !== undefined) {
        onClick();
      } else dispatch(setCurrentTab(title));
    };
    return (
      <div className={styles.MenuElement} onClick={onClickDiv}>
        <div className={styles.FontSize19}>
          {cloneElement(Icon as React.ReactElement, { style })}
        </div>
        <div style={style}>{title}</div>
      </div>
    );
  };

  const OilPumpIconColored = (): JSX.Element => {
    return (
      <OilPumpIcon color={selectedPage === "Site Map" ? ANTD_BLUE : "black"} />
    );
  };

  const BusinessBuildingIconColored = (): JSX.Element => {
    return (
      <BusinessBuildingIcon
        color={selectedPage === "Orgs" ? ANTD_BLUE : "black"}
      />
    );
  };

  const DeviceWarningColored = (): JSX.Element => {
    return (
      <DeviceWarningIcon
        color={selectedPage === "Devices" ? ANTD_BLUE : "black"}
      />
    );
  };

  return (
    <div className={styles.Layout}>
      <div className={styles.Toolbar}>
        <div className={styles.LogoOrgSiteWrapper}>
          <div className={styles.LogoWrapper}>
            <img src={Logo} width="100%" height="100%" />
          </div>
          <div className={styles.OrgSiteWrapper}>{orgSiteTextDisplay}</div>
        </div>

        <div className={styles.IconsWrapper}>
          <MenuIcon
            Icon={<HomeOutlined />}
            title="Home"
            onClick={navigateToMap}
          />
          <MenuIcon
            Icon={<OilPumpIconColored />}
            title="Site Map"
            onClick={navigateToSite}
          />
          <MenuIcon
            Icon={<BarChartOutlined />}
            title="Emissions"
            onClick={navigateToEmissions}
          />
          <MenuIcon
            Icon={<LineChartOutlined />}
            title="Site Data"
            onClick={navigateToSiteData}
          />
          {isAdminUser && (
            <MenuIcon
              Icon={<FundViewOutlined />}
              title="Analytics"
              route="analytics"
            />
          )}
          {isAdminUser && (
            <MenuIcon
              Icon={<DeviceWarningColored />}
              title="Devices"
              route="devices"
            />
          )}

          <MenuIcon
            Icon={<BusinessBuildingIconColored />}
            title="Orgs"
            route="organizations"
          />

          {isAdminUser && (
            <MenuIcon Icon={<TeamOutlined />} title="Users" route="users" />
          )}
          <MenuIcon
            Icon={<BellOutlined />}
            title="Incidents"
            route="incidents"
          />
          {isAdminUser && (
            <MenuIcon
              Icon={<PieChartOutlined />}
              title="KPIs"
              route="kpi-reports"
            />
          )}
        </div>

        <div className={styles.UserInfoWrapper}>
          <div style={{ marginRight: 10 }}>
            <div>
              <div style={{ fontWeight: "bold", fontSize: 12 }}>{userName}</div>
              <div style={{ fontSize: 10 }}>{email}</div>
            </div>
          </div>
          <div>
            <Tooltip title="Logout">
              <Button
                shape="circle"
                style={localStyles.Button}
                onClick={handleSignOut}
                className="sign-out"
                icon={<LogoutOutlined style={localStyles.Icon} />}
              ></Button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={styles.Body}>
        <div className={styles.ChildrenWrapper}>{children}</div>
      </div>
    </div>
  );
};

export default LayoutV2;
