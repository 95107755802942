import { NodeSamplesDisplay, NodeSamplesStatType } from "./types";

export const nodeSamplesDisplayToSelectedStatTypes = (
  nodeSamplesDisplay: NodeSamplesDisplay
): NodeSamplesStatType[] => {
  const result = (
    Object.keys(nodeSamplesDisplay) as Array<keyof NodeSamplesDisplay>
  )
    .filter((key) => nodeSamplesDisplay[key])
    .map((key) => nodeSamplesKeyToStatType(key));
  return result;
};

export const nodeSamplesKeyToStatType = (key: keyof NodeSamplesDisplay): NodeSamplesStatType => {
  const boolToStatTypeMap = {
    doShowCH4: NodeSamplesStatType.CH4,
    doShowVOC: NodeSamplesStatType.VOC,
    doShowCO2: NodeSamplesStatType.CO2,
  };

  return boolToStatTypeMap[key];
};
