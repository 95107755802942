import { FC, useEffect } from "react";
import { Form, Input, Button } from "antd";
import { useAppSelector } from "../../../store/hooks";
import { currentOrganizationSelector } from "../../../store/organizations/selector";
import {
  useCreateBusinessUnitMutation,
  useUpdateBusinessUnitMutation,
} from "../../../store/businessUnits/api";
import { useNavigate } from "react-router-dom";
import { currentBusinessUnitSelector } from "../../../store/businessUnits/selector";

interface BusinessUnitFormProps {
  isEdit: boolean;
}

const BusinessUnitForm: FC<BusinessUnitFormProps> = ({ isEdit = false }) => {
  const [form] = Form.useForm();
  const currentOrganization = useAppSelector(currentOrganizationSelector);
  const currentOrgId =
    currentOrganization !== null ? currentOrganization.OrgId : "";
  const [createNewBusinessUnitTrigger] = useCreateBusinessUnitMutation();
  const [updateBusinessUnitTrigger] = useUpdateBusinessUnitMutation();
  const navigate = useNavigate();
  const currentBusinessUnit = useAppSelector(currentBusinessUnitSelector);

  useEffect(() => {
    if (isEdit && currentBusinessUnit != null) {
      form.setFieldsValue({ tagName: currentBusinessUnit.tagName });
    }
  }, [isEdit, currentBusinessUnit, form]);

  const orgManagementPath = `/organization/${currentOrgId}/organization-management`;

  const onFinish = (values: { tagName: string }): void => {
    if (isEdit && currentBusinessUnit != null) {
      const payload = {
        tagName: values.tagName,
        tagId: currentBusinessUnit.tagId,
      };
      updateBusinessUnitTrigger(payload)
        .unwrap()
        .then((res) => {
          if (res.result.Message === "SUCCESS") {
            window.alert("Business Unit Updated Successfully");
            navigate(orgManagementPath);
          } else {
            window.alert("Error while updating Business Unit");
          }
        })
        .catch((e) => {
          console.error(e);
          window.alert("Error while updating Business Unit");
        });
    } else {
      const payload = {
        tagName: values.tagName,
        orgId: currentOrgId,
      };
      createNewBusinessUnitTrigger(payload)
        .unwrap()
        .then((res) => {
          if (res.result.Message === "SUCCESS") {
            window.alert("Business Unit Created Successfully");
            navigate(orgManagementPath);
          } else {
            window.alert("Error while creating Business Unit");
          }
        })
        .catch((e) => {
          console.error(e);
          window.alert("Error while creating Business Unit");
        });
    }
  };

  return (
    <div>
      <Button
        type="link"
        onClick={() => navigate(orgManagementPath)}
        style={{ marginLeft: 0, paddingLeft: 0, marginBottom: 5 }}
      >
        Go Back
      </Button>
      <h3>{isEdit ? "Edit" : "New"} Business Unit</h3>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        style={{ width: 300 }}
      >
        <Form.Item
          label="Business Unit Name"
          name="tagName"
          rules={[
            { required: true, message: "Business Unit Name is required" },
          ]}
        >
          <Input placeholder="Enter Business Unit Name" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default BusinessUnitForm;
